body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    padding: 20px;
    background-color: #000;
    min-height: 100vh;
    font-family: 'Oswald', sans-serif;
    color: #FFFFFF;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
}

.footer {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.reach-out {
    margin-top: 100px;
}

.content {
    margin: 150px 0;
}

.section {
    min-height: 100vh;
}

.prologue {
    position: absolute;
    top: calc(100vh - (100vw / 3.2));
    font-size: calc(100vw / 6);
    width: calc(100% - 40px);
}

.honors {
    margin-top: 100px;
}

.honors-header {
    font-size: calc(100vw / 7);
    margin: 0;
}

.text {
    font-size: 32px;
}

.absolute-header-section-left {
    position: absolute;
    left: 20px;
    top: 20px;
}

.absolute-header-section-right {
    position: absolute;
    right: 20px;
    top: 20px;
}

.dots {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-left: 5px;
}

.aaaa-red {
    background-color: #A80300;
}

.aaaa-blue {
    background-color: #3444A3;
}

.aaaa-yellow {
    background-color: #eaca15;
}

.aaaa-white {
    background-color: #FFFFFF;
}

.aaaa-grey {
    background-color: darkgrey;
}

.red {
    color: #A80300;
}

.blue {
    color: #3444A3;
}

.yellow {
    color: #eaca15;
}

.white {
    color: #FFFFFF;
}

.flex-table {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.flex-table > p {
    font-size: 24px;
}

.flex-table > p:first-of-type {
    font-size: 32px;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

li {
    border-top: solid 1px #FFFFFF;
    padding: 15px 0 20px 0;
}

li > p {
    margin: 0;
}

li:last-of-type {
    border-bottom: solid 1px #FFFFFF;
}

li > p:first-of-type {
    font-size: 28px;
}

li > a {
    text-decoration: none;
    color: #FFFFFF;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

li > .yellow-hover:hover {
    color: #eaca15;
}

li > .red-hover:hover {
    color: #A80300;
}

li > .blue-hover:hover {
    color: #3444A3;
}

.bio {
    overflow-x: hidden;
}

.bio-content {
    min-height: 400px;
    padding: 2rem 1rem;
    font-size: 32px;
}

.bio-content-span {
    font-size: 48px;
}

.bio-content-points {
    margin-bottom: 1.5rem;
}

#definition {
    padding-top: 3rem;
}

#rotation {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

#rotation-iframe {
    margin-top: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.menu {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10vh 0 25vh;
    --marquee-width: 100%;
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    --item-font-size: 15vw;
    counter-reset: menu;
}

.menu__item {
    cursor: default;
    position: relative;
    padding: 0 5vw;
}

.menu__item-link {
    display: inline-block;
    cursor: pointer;
    position: relative;
    -webkit-text-stroke: 1.5px #fff;
    text-stroke: 1.5px #fff;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    color: transparent;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    text-decoration: none;
}

.outline-text {
    cursor: pointer;
    -webkit-text-stroke: 1.5px #fff;
    text-stroke: 1.5px #fff;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    color: transparent;
}

.menu__item-link::before {
    -webkit-animation: none 0s ease 0s 1 normal none running;
            animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    -webkit-border-image: none;
            border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    -webkit-columns: auto;
            columns: auto;
    -webkit-column-count: auto;
            column-count: auto;
    -webkit-column-fill: balance;
            column-fill: balance;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
    -webkit-column-rule: medium none currentColor;
            column-rule: medium none currentColor;
    -webkit-column-span: 1;
            column-span: 1;
    -webkit-column-width: auto;
            column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    -webkit-perspective: none;
            perspective: none;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    position: static;
    right: auto;
    -moz-tab-size: 8;
         tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    -webkit-transform: none;
            transform: none;
    -webkit-transform-origin: 50% 50% 0;
            transform-origin: 50% 50% 0;
    -webkit-transform-style: flat;
            transform-style: flat;
    -webkit-transition: none 0s ease 0s;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    all: initial;
    color: #fff;
    counter-increment: menu;
    content:  counter(menu);
    position: absolute;
    bottom: 60%;
    left: 0;
    pointer-events: none;
}

.menu__item-link:hover {
    -webkit-transition-duration: 0.1s;
            transition-duration: 0.1s;
    opacity: 0;
}

.marquee {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--marquee-width);
    overflow: hidden;
    pointer-events: none;
    mix-blend-mode: color-burn;
}

.marquee__inner {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-transform: translate3d(var(--move-none), 0, 0);
            transform: translate3d(var(--move-none), 0, 0);
    -webkit-transform: translate3d(var(--move-initial), 0, 0);
            transform: translate3d(var(--move-initial), 0, 0);
    -webkit-animation: marquee 5s linear infinite;
            animation: marquee 5s linear infinite;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
    opacity: 0;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s;
}

.menu__item-link:hover ~ .marquee .marquee__inner {
    -webkit-animation-play-state: running;
            animation-play-state: running;
    opacity: 1;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
}

.marquee span {
    text-align: center;
}

.menu__item-link,
.marquee span {
    white-space: nowrap;
    font-size: var(--item-font-size);
    padding: 0 1vw;
    line-height: 1.15;
}

.marquee span {
    font-style: italic;
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translate3d(var(--move-none), 0, 0);
                transform: translate3d(var(--move-none), 0, 0);
        -webkit-transform: translate3d(var(--move-initial), 0, 0);
                transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        -webkit-transform: translate3d(var(--move-final), 0, 0);
                transform: translate3d(var(--move-final), 0, 0);
    }
}

@keyframes marquee {
    0% {
        -webkit-transform: translate3d(var(--move-none), 0, 0);
                transform: translate3d(var(--move-none), 0, 0);
        -webkit-transform: translate3d(var(--move-initial), 0, 0);
                transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        -webkit-transform: translate3d(var(--move-final), 0, 0);
                transform: translate3d(var(--move-final), 0, 0);
    }
}

@media screen and (min-width: 53em) {
    .frame {
        text-align: left;
        display: grid;
        -webkit-align-content: space-between;
                align-content: space-between;
        width: 100%;
        padding: 2.5rem 6vw;
        pointer-events: none;
        grid-template-columns: 75% 25%;
        grid-template-rows: auto;
    }

    .frame__title-wrap {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: start;
                align-items: start;
    }

    .frame__info {
        margin: 0;
    }

    .frame__title {
        margin: 0 1rem 0 0;
    }

    .frame__links {
        padding: 0;
        justify-self: end;
    }

    .frame a {
        pointer-events: auto;
    }
}

.circle { position: relative; width: 50%; padding-bottom: 50%; overflow: hidden; display: inline-block; }

.circle text { font-family: 'Helvetica Neue', Arial; font-size: 32px; font-weight: bold; }

.circle svg { position: absolute; left: 0; top: 0; width: 100%; height: 400px;

    -webkit-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;

}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@keyframes rotate {
    from { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); transform: rotate(0); }
}

@media screen and (max-width: 767px) {
    .flex-table {
        display: block;
    }

    .flex-table > p {
        font-size: 16px;
        margin: 0;
    }

    .flex-table > p:first-of-type {
        font-size: 32px;
        margin: 0 0 10px 0;
    }

    .circle {
        width: 100%; padding-bottom: 80%; overflow: hidden; display: block;
    }

    .circle svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 200px;
    }
}

.wavy {
    position: relative;
}

.wavy span {
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 2em;
    text-transform: uppercase;
    -webkit-animation: animate 2s ease-in-out infinite;
            animation: animate 2s ease-in-out infinite;
    -webkit-animation-delay: calc(0.1s * var(--i));
            animation-delay: calc(0.1s * var(--i));
}

@-webkit-keyframes animate {
    0% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    20% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    40%,
    100% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes animate {
    0% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    20% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    40%,
    100% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

